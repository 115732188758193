import React, { Component } from 'react';
import './Cards.css';
import CardItem from './CardItem';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

class Cards extends Component {

  handleClick(lang) {
    i18next.changeLanguage(lang)
  }

  render() {
    const { t } = this.props;

  return (
    <div className='cards'>
      <h1>{t('textcheckProject')}</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/khaos.png'
              label='Khaos'
              text= {t('textKhaosCard')}
              path='/khaos'
            />
            <CardItem
              src='images/Stratego.png'
              text={t('textstrategocard')}
              label='Stratego'
              path='/stratego'
            />
           <CardItem
              src='images/image-coming-soon.jpg'
              text={t('comingSoonText')}
              label={t('comingSoonLabel')}
              path='/'
            />
          </ul>
          {/*
          <ul className='cards__items'>
          
          <CardItem
              src='images/image-coming-soon.jpg'
              text={t('textsymfonycard')}
              label='Symfony'
              path='/symfony'
            />
            
          </ul>
          
          <ul className='cards__items'>
            
             
            <CardItem
              src='images/img-4.jpg'
              text='Experience Football on Top of the Himilayan Mountains'
              label='Adventure'
              path='/products'
            />
            <CardItem
              src='images/img-8.jpg'
              text='Ride through the Sahara Desert on a guided camel tour'
              label='Adrenaline'
              path='/sign-up'
            />
            
          </ul>*/}
        </div>
      </div>
    </div>
  );
}
}

export default withTranslation()(Cards);
