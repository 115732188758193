import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import './HeroSectionProject.css';



function HeroSectionProject(props){
    let haveVideo = false;
    let haveLink=false;
    
    if(props.videourl !== undefined)
    {
        haveVideo =true;
    }
    if(props.linkURL !== undefined && props.linkText !== undefined)
    {
      haveLink=true;
    }
  
  return (
    <div className='container'>
      
      <h1>{props.title}</h1>
     
      <p>{props.text}</p>
      {haveVideo ? <video src={props.videourl} autoPlay loop muted></video>:""}
      {haveLink ?  
      <Link to={props.linkURL}>
        <p>{props.linkText}</p>
      </Link>:""}

        
      
    </div>
  );
}


export default HeroSectionProject;
