import React, { Component } from 'react'
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import NotFoundPage from './components/pages/NotFoundPage';
import Work from './components/pages/Work';
import Khaos from './components/pages/Khaos';
import Stratego from './components/pages/Stratego';

import './App.css';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

class App extends Component {

  handleClick(lang) {
    i18next.changeLanguage(lang)
  }
  
  render() {
  

    return (
      <>
        
        <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Work} />
          <Route path='/khaos' exact component={Khaos} />
          <Route path='/stratego' exact component={Stratego} />
          <Route path='*' exact component={NotFoundPage}/>
        </Switch>
      </Router>
      </>
    )
  }
}

export default withTranslation()(App);
