import React, { Component } from 'react';
import '../../App.css';
import Footer from '../Footer';
import HeroSectionProject from '../HeroSectionProject';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';


class Khaos extends Component {

  handleClick(lang) {
    i18next.changeLanguage(lang)
  }
 

  render() {
    window.scrollTo(0,0);   
    const { t } = this.props;
    
  
  return (
    <>
      <HeroSectionProject
        title={t('khaos.title')}
        text={t('khaos.text')}
      />
      <HeroSectionProject 
        text={t('khaos.textmouvement')}
        videourl="videos/khaos_mouvement.mp4"
      />
      <HeroSectionProject 
        text={t('khaos.textcombat')}
        videourl="videos/khaos_fight.mp4"
      />
      <HeroSectionProject 
        text={t('khaos.textmenu')}
        videourl="videos/khaos_menu.mp4"
      />
      <br/>
      <Footer />
    </>
  );
}
}

export default withTranslation()(Khaos);
