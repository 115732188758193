import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

class Navbar extends Component {
  
  handleClick(lang) {
    i18next.changeLanguage(lang)
  }



render() {




  const { t } = this.props;

  return (
    <>

      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' >
              SamTheDev
          </Link>
          <div className='menu-icon'>
            <i className={true ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={true ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' >
                {t('work')}
              </Link>
            </li>
            {/*<li className='nav-item'>
              <Link
                to='/about'
                className='nav-links'
              >
                {t('about')}
              </Link>
            </li>*/}
          </ul>        
              <img className='nav-img' alt="fr" src="images/logoFR.png" onClick={()=>this.handleClick('fr')} />       
              <img className='nav-img' alt="en" src="images/logoEN.png" onClick={()=>this.handleClick('en')} />
            
        </div>
      </nav>
    </>
  )
}
}

export default withTranslation()(Navbar);
