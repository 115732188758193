import React, { Component } from 'react';
import '../App.css';
import './HeroSection.css';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

class HeroSection extends Component {
  handleClick(lang) {
    i18next.changeLanguage(lang)
  }

render(){
  const { t } = this.props;
  return (
    <div className='hero-container'>
      <img className='imghero' alt="" src='images/HeroBg.png'/>
      
      <h1>{t('texthi1')}<br/>{t('texthi2')}</h1>
     
      <p>{t('textimlearning')}</p>
      
    </div>
  );
}
}

export default withTranslation()(HeroSection);
