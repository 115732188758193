import React, { Component } from 'react';
import '../../App.css';
import Footer from '../Footer';
import HeroSectionProject from '../HeroSectionProject';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';


class NotFoundPage extends Component {

    handleClick(lang) {
        i18next.changeLanguage(lang)
      }
    render() {

        const { t } = this.props;
        window.scrollTo(0,0); 
        return (
            <>
                <HeroSectionProject
                    title={t('404Title')}
                    text={t('404text')}
                    linkText={t('404link')}
                    linkURL="/"  
                />
                <Footer />
            </>
  );
}
}
export default withTranslation()(NotFoundPage);
