import React, { Component } from 'react';
import '../../App.css';
import Footer from '../Footer';
import HeroSectionProject from '../HeroSectionProject';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';


class Stratego extends Component {

  handleClick(lang) {
    i18next.changeLanguage(lang)
  }
 

  render() {
    window.scrollTo(0,0);   
    const { t } = this.props;
    
  
  return (
    <>
      <HeroSectionProject
        title={t('stratego.title')}
        text={t('stratego.text')}
      />
      <HeroSectionProject 
        text={t('stratego.textcreategame')}
        videourl="videos/newgame_stratego.mp4"
      />
      <HeroSectionProject 
        text={t('stratego.textgame')}
        videourl="videos/game_stratego.mp4"
      />
      <br/>
      <Footer />
    </>
  );
}
}

export default withTranslation()(Stratego);
